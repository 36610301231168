<template>
  <component
    :is="icons[name]"
    :class="classes"
  />
</template>

<script lang="ts" setup>
import * as icons from '~/assets/icons';
import type { TIconNames } from '~/assets/icons';
import type { TColors } from '~/types/components/colors';

const props = withDefaults(defineProps<{
  name: TIconNames
  size?: 's' | 'm' | 'l' | 'xl' | 'auto'
  color?: TColors
}>(), {
  size: 'm',
  color: undefined,
});

const classes = computed(() => ({
  'osk-icon': true,
  [`osk-icon_size-${props.size}`]: !!props.size,
  [`text-${props.color}`]: !!props.color,
}));
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-icon {
  display: inline-flex;
  vertical-align: middle;
  color: $black;

  &_size-s {
    width: $icon-size-s;
    height: $icon-size-s;
  }

  &_size-m {
    width: $icon-size-m;
    height: $icon-size-m;
  }

  &_size-l {
    width: $icon-size-l;
    height: $icon-size-l;
  }

  &_size-xl {
    width: $icon-size-xl;
    height: $icon-size-xl;
  }

  &_size-auto {
    width: auto;
    height: auto;
  }
}
</style>
